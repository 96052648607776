//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SoftwareLicenseAgreement from "@/components/SoftwareLicenseAgreement";
export default {
  components: {
    SoftwareLicenseAgreement,
  },
  data() {
    return {
      teamoptions: [], //团队选项
      loading: false,
      input: "",
      password: "",
      teamValue: null, //选择的团队
      ruleForm: {
        Email: "",
      },
      rules: {
        Email: [
          {
            required: true,
            message: "请输入邮箱地址或机器码",
            trigger: "blur",
          },
          // {
          //   type: "email",
          //   message: "请输入正确的邮箱地址",
          //   trigger: ["blur", "change"],
          // },
        ],
      },
      showLogin: true,
      loadSubmit: false,
      agree: false,
    };
  },
  computed: {
    isSell() {
      return process.env.VUE_APP_SELL;
    },
    appName() {
      return process.env.VUE_APP_NAME;
    },
  },
  mounted() {
    if (
      localStorage.getItem("loginPageSoftwareLicenseAgreementAgree") !== "true"
    )
      this.showLicense();
    else this.agree = true;
    // this.getTeams();
  },
  methods: {
    /**
     * 点击立即注册
     */
    handleRegister() {
      this.$router.push("/register");
    },
    /**
     * 获取团队
     */
    getTeams() {
      this.$http
        .get("/Teams/GetAllTeams.ashx", {
          params: { searchText: null, type: 1 },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.teamoptions = resp.data;
          }
        });
    },
    onConfirm(ruleForm) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadSubmit = true;
          this.$http
            .post("/ForegetPassword.ashx", {
              Email: this.ruleForm.Email,
            })
            .then((res) => {
              if (res.res == 0) {
                this.$modal.hide("forgetpw");
                this.$message.success(res.msg);
              }
            })
            .finally(() => (this.loadSubmit = false));
        } else {
          return false;
        }
      });
    },
    login() {
      if (!this.agree) return this.showLicense();

      if (!this.input || !this.password) {
        this.$notify.error("请填入账号密码");
      } else {
        this.loading = true;
        let params = {
          acc: this.input,
          pwd: this.password,
          teamId: this.teamValue,
        };
        this.$http
          .post("/UserLogin.ashx", params)
          .then((req) => {
            //判断返回的数据进行不同操作 以下默认为验证通过跳转到主页
            this.loading = false;
            if (req.res == 0) {
              this.$message({
                message: "登陆成功",
                type: "success",
              });
              this.$xStorage.setItem("token", req.data.token, req.data.exp);
              this.$xStorage.setItem(
                "user-role",
                {
                  role: req.data.RoleName,
                  team: this.teamValue,
                },
                req.data.exp
              );
              const query = window.location.search.substr(1).split("&");
              for (let item of query) {
                if (item.indexOf("callback") >= 0) {
                  this.$router.push(decodeURIComponent(item.split("=")[1]));
                  return;
                }
              }
              if (req.data.RoleName.indexOf("管理员") != -1) {
                this.$router.push({
                  path: "/manager",
                });
              } else {
                //如果是从邀请界面过来，登录成功后跳转到邀请界面
                const url = this.$route.query.invitUrl;
                if (url) {
                  this.$router.push({
                    path: url,
                  });
                } else {
                  this.$router.push({
                    path: "/home",
                  });
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    showLicense(e) {
      // if(e?.toString() === '[object PointerEvent]') e.defaultPrevented()
      this.$refs.license.show();
    },
    handleLicenseChange(v) {
      localStorage.setItem("loginPageSoftwareLicenseAgreementAgree", v);
      if (v) this.agree = true;
    },

    // inputchange() {
    //   let bool = /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g;
    //   if (bool.test(this.input)) {
    //     //验证邮箱格式
    //   }
    // },
  },
};
